export const environment = {
  production: false,
  apiUrlBsV1: "https://sandbox-api.poshvine.com/bs/v1/",
  apiUrlCsV1: "https://sandbox-api.poshvine.com/cs/v1/",
  apiUrlPaV1: "https://sandbox-api.poshvine.com/pa/v1/",
  apiUrlPsV1: "https://sandbox-api.poshvine.com/ps/v1/",
  apiUrlBsInternal: "https://sandbox-api.poshvine.com/bs/internal/",
  healthWellnessId: "047cf3a3-ac9b-4eaa-b9e9-922558c95cfb",
  clientId: "3a44dccd-1e46-4d19-8e35-1e6ebe9f49ec",
  projectId: "48ffaffb-b869-4922-9674-106b5eb68e71",
  fitTv: {
    basicVideoUrl: "https://apistage.fitn.in:5555/fit-tv/",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDY3MjgyNjIsInN1YiI6ImZpdHRlcm5pdHkiLCJuYW1lIjoicG9zaHZpbmUiLCJlbnYiOiJzdGFnZSJ9.FBVhkECNFpJxjoFWL4VeUINad8HfwRsUNyGpZlFZ6ak",
  },
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "axis-aura",
  SENTRY_AUTH_TOKEN:
    "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",
  sentryDsn:
    "https://78d18d2a586297a6d6b51d56ed64d09b@o515678.ingest.us.sentry.io/4507372701614080",
};
